import { intl } from "@tci18n/vue2";import cacheHelper from '@/view/common/cache';
import { removeSession } from '@/libs/stroage';
import Cookies from '@/libs/cookie';

/**
 * 模板配置源枚举
 */
const templateModeEnum = {
  'lvzhi': { id: 'LVZHI', name: intl.$t("人事管理"), logo: '', favicon: 'favicon_lvzhi.ico' }
};

export function getTemplateMode() {
  // let hostname = window.location.hostname;
  // let pathname = window.location.pathname;

  return templateModeEnum.lvzhi;
}

/**
 * 设置页面 favicon
 */
function setFavicon() {
  let mode = getTemplateMode();
  let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = mode.favicon;
  document.getElementsByTagName('head')[0].appendChild(link);
  document.title = mode.name;
}

/**
 * 解析菜单列表
 * @param { Array } list 菜单列表
 * @returns { Array } 解析后的菜单列表
 */
function parseMenuList(list) {
  let res = [];
  list.forEach((item) => {
    let obj = {
      icon: item.resourceIcon,
      name: item.resourceAlias.replace(/\//g, "_"),
      meta: {
        url: item.resourceUrl || '',
        title: item.resourceName
      }
    };
    if (item.children && item.children.length) {
      obj.children = parseMenuList(item.children);
    }
    res.push(obj);
  });
  return res;
}

/**
 * 检查是否具有页面路由权限
 * @note 权限来自 Office、HRMS 配置
 * @param { String } path 页面路由地址
 * @returns { Number } 1：具有权限，2：无权限，3：无菜单、页面数据
 */
function testPageUrlPower(path) {
  let urls = cacheHelper.getCachedPageUrls();
  if (urls) {
    return urls.some((item) => {
      if (item == path) {
        return true;
      }
    }) ? 1 : 2;
  } else {
    return 3;
  }
}


/**
 * 跳转至统一登录
 */
function loginByOAuth(isAutoLogout = 'N') {
  /**
   * 退出，区分手动退出和自动退出
   * 自动退出，不清空cookie
   * 手动退出，清空cookie 也清空session
   * 不管自动退出还是手动退出，都要判断cookie和session是否一致，不一致，清空session
   */
  if (isAutoLogout === 'Y') {
    cacheHelper.clearLocalCache();
    removeSession('s-token');
    Cookies.remove('x-access-titc-s-token');
  } else {
    removeSession('s-token');
  }

  let hostname = window.location.hostname;
  let protocol = window.location.protocol;
  // let pathname = window.location.pathname;
  // let hostItems = hostname.split('.');
  let loginUrl = '';
  // let mode = getTemplateMode();
  if (process.env.NODE_ENV === 'development') {
    let localUrl = window.location.origin;
    loginUrl = `${protocol}//10.181.115.92:5173?localUrl=${localUrl}`;
    // loginUrl = `sso.qa.zhuzher.com/woauth/#login?referUrl=http://ehr.qa.zhuzher.com&localUrl=${localUrl}`;

  } else {

    const hostItems = window.location.hostname;
    let sso = hostItems.split('.');

    if (sso[0].includes('-qa')) {
      sso[0] = 'sso-qa';
    }if (sso[0].includes('-uat')) {
      sso[0] = 'sso-uat';
    } else {
      sso[0] = 'sso';
    }

    loginUrl = `${protocol}//${sso.join('.')}/login?referUrl=${protocol}//${hostItems}`;

    //   const prefix = hostItems[0];
    //   hostItems[0] = 'sso';
    //   loginUrl = hostItems.join('.') + '/woauth/#login';
    //   hostItems[0] = prefix;
    //   let referUrl = `?referUrl=${protocol}//${hostItems.join('.')}`;
    //   loginUrl += referUrl;
    // }
    // loginUrl = protocol + '//' + loginUrl;
    // if (/\/[a-z]+/i.test(pathname)) {
    //   loginUrl += pathname;

  }
  console.log('跳 转 至 统 一 登 录 Url', loginUrl);
  window.location.href = loginUrl;
}


/**
 * 系统层公共逻辑
 */
export default {
  setFavicon,
  getTemplateMode,
  parseMenuList,
  testPageUrlPower,
  loginByOAuth
};